@import "../../styles/Colors.scss";

.padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 12rem;
  padding-right: 12rem;
}

.text-header {
  color: $secondary;
}

.background {
  background-color: $primary;
}
