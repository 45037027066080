@import "../../styles/Colors.scss";
@import "~bootstrap/scss/bootstrap";

.text-header {
  color: $primary;
}

.contact-form {
  padding: 2rem;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.custom-form {
  outline: none;
  box-shadow: none;
  border-radius: 3px;
  min-width: 20rem;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary;
  }
}

.custom-button {
  min-width: 20rem;
  background-color: $primary;
  transition: 0.3s;
  border-radius: 3px;

  &:hover {
    background-color: darken($color: $primary, $amount: 3) !important;
  }

  &:active {
    background-color: darken($color: $primary, $amount: 3) !important;
  }
}
