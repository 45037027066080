@import "../../styles/Colors.scss";

.text-header {
  color: black;
}

.padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 10rem;
  padding-right: 10rem;
}

.plans {
  background-color: $lightgrey;
}
