@import "./Colors.scss";

.btn-custom-outline {
  border-radius: 2px !important;
  border: 3px solid $blue !important;
  background: transparent !important;
  color: $blue !important;
  transition: 0.3s !important;

  &:hover {
    background: $blue !important;
    color: white !important;
    border: 3px solid $blue !important;
  }

  &:active {
    background: lighten($color: $blue, $amount: 10) !important;
    color: white !important;
    border: 3px solid lighten($color: $blue, $amount: 10) !important;
  }
}

.btn-custom-primary {
  background-color: $primary !important;
  color: $white !important;
  border-color: $primary !important;
  border-radius: 2px !important;
  transition: 0.3s !important;

  &:hover {
    background: darken($color: $primary, $amount: 8) !important;
    border-color: darken($color: $primary, $amount: 8) !important;
  }

  &:active {
    background: lighten($color: $primary, $amount: 8) !important;
    border-color: lighten($color: $primary, $amount: 8) !important;
  }
}

.btn-custom-secondary {
  background-color: $secondary !important;
  color: $white !important;
  border-color: $secondary !important;
  transition: 0.3s !important;
  border-radius: 2px !important;

  &:hover {
    background-color: darken($color: $secondary, $amount: 8) !important;
    border-color: darken($color: $secondary, $amount: 8) !important;
  }

  &:active {
    background-color: lighten($color: $secondary, $amount: 10) !important;
    border-color: lighten($color: $secondary, $amount: 10) !important;
  }
}
