@import "./styles/Animations.scss";
@import "./styles/Buttons.scss";
@import "./styles/Colors.scss";
@import "./styles/Forms.scss";
@import "./styles/Sections.scss";
@import "./styles/Top.scss";
@import "./styles/FlexTools.scss";

@import "~bootstrap/scss/bootstrap";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
