@import "../../styles/Colors.scss";

.text-header {
  color: $primary;
}

.padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 12rem;
  padding-right: 12rem;
}
