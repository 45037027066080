@import "../../styles/Colors.scss";

.main-block {
  padding-top: 74px;
}

.heading-box {
  border: 4px solid $white;
  padding: 2rem;

  h1 {
    color: $white;
    text-transform: uppercase;
  }
}

.paragraph {
  font-size: 20px;
  color: transparentize($color: $white, $amount: 0.3);
}

.padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 12rem;
  padding-right: 12rem;
}

.text-button {
  font-weight: 500;
  font-size: 18px;
}
