.flex-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}
