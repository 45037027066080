@import "../../styles/Colors.scss";

.message {
  background-color: #aed6fd;
  color: $black;
}

.text-header {
  color: $black;
}

.image-partners {
  margin: 1.5rem;
  height: 5rem;
}
