@import "./Colors.scss";

.padding {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.navbar {
  transition: 0.3s !important;
}

.navbar-opacity-1 {
  opacity: 1 !important;
  background-color: $white !important;
}

//MEDIA QUERY

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

.padding {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

@media only screen and (min-width: 992px) {
  .padding {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .padding {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
