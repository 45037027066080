@import '../../styles/Colors.scss';

.who-block {
  background-color: $lightgrey;
  color: white;
}

.padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 12rem;
  padding-right: 12rem;
}

.text-header {
  color: $primary;
}

.image-01 {
  width: 225px;
  z-index: 1;
  transform: translate(0, 50%);
  transition: 0.3s;
  border: 6px solid white;

  &:hover {
    transform: translate(0, 40%);
  }
}

.image-02 {
  width: 450px;
  transform: translate(-40%, -10%);
  transition: 0.3s;
  border: 6px solid white;

  &:hover {
    transform: translate(-40%, -15%);
  }
}
